/**
 * ANGULAR CORE
 */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';

/**
 * PAGINA PRINCIPAL
 */
import {CrearCuenta} from './crear-cuenta.page';

//prettier-ignore
@NgModule({
  declarations: [CrearCuenta],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule
   ],
  exports: [CrearCuenta],
  providers: [],
})
export class CrearCuentaModule {}
