import {Component, OnInit} from '@angular/core';
import {HelperService} from 'helpers/helper.service';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
	selector: 'app-login-account-dialog',
	templateUrl: './login-account-dialog.page.html',
	styleUrls: ['../account-dialog.scss', './login-account-dialog.page.scss']
})
export class LoginAccountDialogPage implements OnInit {
	ruta!: string;
	//prettier-ignore
	constructor(
    public ref: DynamicDialogRef,
		public config: DynamicDialogConfig,
  ) { }

	public ngOnInit(): void {
		if (this.config.data?.otherData) {
			this.ruta = this.config.data.otherData.ruta;
		}
	}

	/**
	 * Cierra la ventana de dialogo
	 * @returns
	 */
	public cerrar = () => this.ref.close();
}

