import {Component, OnInit} from '@angular/core';
import {HelperService} from 'helpers/helper.service';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
	selector: 'create-account-dialog-page',
	templateUrl: './create-account-dialog.page.html',
	styleUrls: ['../account-dialog.scss', './create-account-dialog.page.scss']
})
export class CreateAccountDialogPage implements OnInit {
	//prettier-ignore
	constructor(
    public ref: DynamicDialogRef,
		public config: DynamicDialogConfig,
  ) {}

	public ngOnInit(): void {}

	/**
	 * Cierra la ventana de dialogo
	 * @returns
	 */
	public cerrar = () => this.ref.close();
}
