import {AuthService} from './services/auth.service';
import {HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {Observable, from, switchMap} from 'rxjs';
export const BYPASS_INTERCEPTOR = new HttpContextToken(() => false);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	//prettier-ignore
	constructor(
    private authService: AuthService
  ) {}

	public intercept = (request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> =>
		from(this.authService.getUserToken()).pipe(
			switchMap((token: string | undefined) => {
				if (request.context.get(BYPASS_INTERCEPTOR)) {
					return next.handle(request);
				}
				if (token) {
					request = request.clone({
						setHeaders: {
							Authorization: `Bearer ${token}`
						}
					});
				}
				return next.handle(request);
			})
		);
}

