<ng-container *ngIf="displayBanner">
	<ng-container [ngSwitch]="modo">
		<ng-container *ngSwitchCase="'desktop'">
			<div class="banner">
				<div class="fondo">
					<img src="assets/images/banner-fondo-desktop.png" />
				</div>
				<div class="text-box">
					<div class="text-1">¡Crea tu propia tienda online!</div>
					<div class="button-wrapper">
						<button (click)="goToCreateTienda()">Crear tienda</button>
					</div>
					<div class="text-2">Totalmente <span class="free-text">Gratis</span></div>
				</div>
				<div class="close-button">
					<button>
						<i (click)="closeBanner()" class="pi pi-times"></i>
					</button>
				</div>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="'mobile'">
			<div class="banner-mobile">
				<div class="fondo">
					<img src="assets/images/banner-fondo-mobile.png" />
				</div>
				<div class="text-box">
					<div class="text-1">¡Crea tu propia tienda online! <span class="free-text">Gratis</span></div>
					<div class="button-wrapper">
						<button (click)="goToCreateTienda()">Crear tienda</button>
					</div>
				</div>
				<div class="close-button">
					<button>
						<i (click)="closeBanner()" class="pi pi-times"></i>
					</button>
				</div>
			</div>
		</ng-container>
	</ng-container>
</ng-container>

