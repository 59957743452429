/**
 * ANGULAR CORE
 */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';

/**
 * PAGINA PRINCIPAL
 */
import {CreateAccountDialogPageRoutingModule} from './create-account-dialog-routing.module';
import {CreateAccountDialogPage} from './create-account-dialog.page';

/**
 * CREAR CUENTA
 */
import {CrearCuentaModule} from '../../reutilizables/crear-cuenta/crear-cuenta.module';

//prettier-ignore
@NgModule({
  declarations: [CreateAccountDialogPage],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CreateAccountDialogPageRoutingModule,
    CrearCuentaModule
   ],
  exports: [CreateAccountDialogPage],
  providers: [],
})
export class CreateAccountDialogPageModule {}
