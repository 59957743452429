import {inject} from '@angular/core';
import {DocumentData, FirestoreError, onSnapshot, orderBy, query, QuerySnapshot, where} from 'firebase/firestore';
import {HelperService} from 'helpers/helper.service';
import {Categorias} from 'interfaces/categorias.interface';
import {SnapshotObserver} from 'interfaces/observer.interface';
import {Product} from 'interfaces/product.interface';

export class ObserverController {
	private readonly helpers = inject(HelperService);

	arrayProductos: any = [];
	productos: Product[] = [];
	categorias: Categorias[] = [];

	//prettier-ignore
	constructor() {}

	/**
	 * Obtiene la captura de las categorias segun el Id del negocio.
	 * @param {string} negocioId
	 * @param {Function} setObserver
	 * @returns
	 */
	public obtenerCategorias = async (negocioId: string, nextCategoryObserver: Function) =>
		onSnapshot(query(this.helpers.collection('categorias'), where('negocio_id', '==', negocioId), orderBy('name', 'asc')), this.setCategoryObserver(nextCategoryObserver));

	/**
	 * Obtiene la captura de los productos segun la categoria.
	 * @param {Function} setObserver
	 * @param {Categorias[]} categorias
	 * @returns
	 * this.helpers.collection('productos', categoria.id!)
	 */
	public obtenerProductos = (categorias: Categorias[], nextProductObserver: Function) =>
		categorias.map((categoria: Categorias, index: number) => onSnapshot(query(this.helpers.collection('productos'), where('cat_id', '==', categoria.id!)), this.setProductObserver(index, nextProductObserver)));

	/**
	 * Obtiene la captura del carrito del usuario logueado.
	 * @param uid
	 * @param nextCarritoObserver
	 * @returns
	 */
	public obtenerCarrito = (uid: string, nextCarritoObserver: Function) => onSnapshot(query(this.helpers.collection('users', uid, 'carrito')), this.setCarritoObserver(nextCarritoObserver));

	/**
	 * Obtiene la captura del carrito
	 * @param uid
	 * @param nextCarritoObserver
	 * @returns
	 */
	public obtenerCarritoNegocio = (uid: string, negocio_id: string, nextCarritoObserver: Function) =>
		onSnapshot(query(this.helpers.collection('users', uid, 'carrito'), where('negocio_id', '==', negocio_id)), this.setCarritoObserver(nextCarritoObserver));

	/**
	 * Observable de las categorias.
	 * @returns SnapshotObserver<DocumentData>
	 */
	public setCategoryObserver = (nextCategoryObserver: Function): SnapshotObserver<DocumentData> => ({
		next: (querySnapshot: QuerySnapshot<DocumentData>) => nextCategoryObserver(querySnapshot),
		error: (error: FirestoreError) => this.helpers.firebaseErrors({error: error})
	});

	/**
	 * Observable de los productos.
	 * @param {number} index
	 * @returns SnapshotObserver<DocumentData>
	 */
	public setProductObserver = (index: number, nextProductObserver: Function): SnapshotObserver<DocumentData> => ({
		next: (querySnapshot: QuerySnapshot<DocumentData>) => nextProductObserver(querySnapshot, index),
		error: (error: FirestoreError) => this.helpers.firebaseErrors({error: error})
	});

	/**
	 * Observable de los carritos.
	 * @param {number} index
	 * @returns SnapshotObserver<DocumentData>
	 */
	public setCarritoObserver = (nextProductObserver: Function): SnapshotObserver<DocumentData> => ({
		next: (querySnapshot: QuerySnapshot<DocumentData>) => nextProductObserver(querySnapshot),
		error: (error: FirestoreError) => this.helpers.firebaseErrors({error: error})
	});
}
