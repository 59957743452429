import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CreateAccountDialogPage} from './create-account-dialog.page';

const routes: Routes = [
	{
		path: '',
		component: CreateAccountDialogPage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class CreateAccountDialogPageRoutingModule {}
