import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {AuthService} from 'src/app/services/auth.service';

@Component({
	selector: 'crear-cuenta',
	templateUrl: './crear-cuenta.page.html',
	styleUrls: ['../cuenta.scss', './crear-cuenta.page.scss']
})
export class CrearCuenta implements OnInit {
	@Input() ref?: DynamicDialogRef;

	//prettier-ignore
	constructor(
    private authService: AuthService
  ) {}

	public ngOnInit(): void {}

	/**
	 * Registro de un usuario con Google.
	 * @returns
	 */
	public createUserWithGoogle = async () => ((await this.authService.googleCreateLoginAccount()) ? this.ref?.close() : undefined);

	/**
	 * Registro de un usuario con Facebook.
	 * @returns
	 */
	public createUserWithFacebook = async () => ((await this.authService.facebookCreateLoginAccount()) ? this.ref?.close() : undefined);
}
