import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AdministratorGuard} from './guards/admin.guard';
import {AutenticatedGuard} from './guards/autenticated.guard';
import {PerfilUsuarioResolver} from './pages/usuario/perfil-usuario/perfil-usuario-resolver';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./pages/index/index.module').then((m) => m.IndexModule)
	},
	{
		path: 'tienda',
		loadChildren: () => import('./pages/tienda/tienda.module').then((m) => m.TiendaPageModule),
		canActivate: [AutenticatedGuard]
	},
	{
		path: 'messages',
		loadChildren: () => import('./pages/messages/messages.module').then((m) => m.MessagesPageModule),
		canActivate: [AutenticatedGuard]
	},
	{
		path: 'carrito/:negocio_id',
		loadChildren: () => import('./pages/productos/carrito/carrito.module').then((m) => m.CarritoPageModule),
		canActivate: [AutenticatedGuard]
	},
	{
		path: 'cotizacion/:id',
		loadChildren: () => import('./pages/productos/cotizacion/cotizacion.module').then((m) => m.CotizacionPageModule)
	},
	{
		path: 'pdf/:id',
		loadChildren: () => import('./pages/productos/carrito/pdfviewer/pdfviewer.module').then((m) => m.PDFViewerModule)
	},
	{
		path: ':identificador',
		resolve: {
			negocio: PerfilUsuarioResolver
		},
		loadChildren: () => import('./pages/usuario/perfil-usuario/perfil-usuario.module').then((m) => m.PerfilUsuarioPageModule)
	},
	{
		path: ':identificador/:producto',
		loadChildren: () => import('./pages/usuario/perfil-usuario/perfil-usuario.module').then((m) => m.PerfilUsuarioPageModule)
	},

	{
		path: 'home',
		redirectTo: ''
	},
	{
		path: '**',
		loadChildren: () => import('./reutilizables/errores/no-encontrado/no-encontrado.module').then((m) => m.NoEncontradoModule)
	}
];
@NgModule({
	imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
	exports: [RouterModule]
})
export class AppRoutingModule {}

