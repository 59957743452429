import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'mensajes',
	templateUrl: './mensajes.page.html',
	styleUrls: ['./mensajes.page.scss']
})
export class MensajesPage implements OnInit {
	constructor() {}

	/**
	 * Inicializamos el componente.
	 */
	public ngOnInit(): void {}
}
