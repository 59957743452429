import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

@Component({
	selector: 'entrar-cuenta',
	templateUrl: './entrar-cuenta.page.html',
	styleUrls: ['../cuenta.scss', './entrar-cuenta.page.scss']
})
export class EntrarCuenta implements OnInit {
	@Input() ref?: DynamicDialogRef;
	@Input() ruta!: string;

	//prettier-ignore
	constructor(
		private authService: AuthService,
		private route: ActivatedRoute,

	) {
	}

	public ngOnInit(): void {
		this.route.params.subscribe((params: any) => (params.return ? (this.ruta = params.return) : null));

	}

	/**
	 * Inicio de sesion de un usuario con Google.
	 */
	public loginWithGoogle = async () => ((await this.authService.googleCreateLoginAccount(this.ruta)) ? this.ref?.close() : undefined);

	/**
	 * Inicio de sesion de un usuario con Facebook.
	 * @returns
	 */
	public logInWithFacebook = async () => ((await this.authService.facebookCreateLoginAccount(this.ruta)) ? this.ref?.close() : undefined);
}

