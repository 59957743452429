// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from 'interfaces/environment.interface';

export const environment: Environment = {
	production: false,
	firebaseConfig: {
		apiKey: 'AIzaSyCPhpmpxOpbxnGAbWQ_gjVrpgTU_tvZA1Q',
		projectId: 'fenix-e451f',
		storageBucket: 'fenix-e451f.appspot.com',
		messagingSenderId: '660418251524',
		appId: '1:660418251524:web:a11e0e9ea4cf3099e25e51',
		measurementId: 'G-DJM20W0QFQ',
		authDomain: 'app-fenix.com',
		databaseURL: 'https://fenix-e451f.firebaseio.com'
	},
	api_url_debug: 'http://127.0.0.1:5001/fenix-e451f/us-central1',
	api_url_prod: 'https://us-central1-fenix-e451f.cloudfunctions.net'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
