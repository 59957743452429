/**
 * ANGULAR CORE
 */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';

/**
 * PAGINA PRINCIPAL
 */
import {LoginAccountDialogPageRoutingModule} from './login-account-dialog-routing.module';
import {LoginAccountDialogPage} from './login-account-dialog.page';

/**
 * ENTRAR CUENTA
 */
import {EntrarCuentaModule} from '../../reutilizables/entrar-cuenta/entrar-cuenta.module';

//prettier-ignore
@NgModule({
  declarations: [LoginAccountDialogPage],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    LoginAccountDialogPageRoutingModule,
    EntrarCuentaModule
  ],
})
export class LoginAccountDialogPageModule {}

