import {Injectable} from '@angular/core';
import {where} from '@angular/fire/firestore';
import {HelperService} from 'helpers/helper.service';
import {Property} from 'interfaces/helper.interface';
import {Metadata} from 'interfaces/metadata.interace';

@Injectable({
	providedIn: 'root'
})
export class MetaDataService {
	//prettier-ignore
	constructor(
    private helpers: HelperService
  ) {}
	/**
	 *
	 * @description Obtenemos los meta datos del negocio
	 * @param negocioId id del negocio
	 */
	public getMetaData = async (negocioId: string) =>
		this.helpers
			.obtenerDocumentos('negocios-meta-data', [], where('negocioId', '==', negocioId))
			.then((datos: Metadata[]) => datos[0])
			.then((data: Metadata) =>
				(<Property<Function>>{
					true: () => ({
						title: data.metaTitulo,
						description: data.metaDescripcion,
						url: data.metaUrl
					}),
					false: () => null
				})[Boolean(data?.metaTitulo).toString()]()
			) as Promise<{title: string; description: string; url: string}>;
}

