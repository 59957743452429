import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

export const AutenticatedGuard = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const authService = inject(AuthService);
	const router = inject(Router);

	/**
	 * Redirecciona a una ruta especificada.
	 * @param route
	 * @param enter
	 * @param lastRoute
	 * @returns
	 */
	const redirect = async (route: string, enter: boolean, lastRoute?: string): Promise<boolean> => {
		if (lastRoute) {
			await router.navigate([route, {return: lastRoute}]);
			window.location.reload();
		} else {
			await router.navigate([route]);
			window.location.reload();
		}
		return enter;
	};

	//(lastRoute ? (await router.navigate([route, {return: lastRoute}])) && enter : (await router.navigate([route])) && enter);

	return await authService
		.compareExistence()
		.then(async (user: any) => (user ? true : await redirect('/login-account', false, state.url)))
		.catch(() => redirect('/login-account', false));
};
