<div class="right-wrapper">
	<div class="logo-fenix">
		<img src="assets/images/Logo_Fenix.png" alt="Logo Fenix" />
	</div>
	<div class="crear-cuenta">
		<div class="crear-cuenta-wrapper">
			<div class="title">
				<h4>CREAR CUENTA</h4>
			</div>
			<div class="buttons">
				<span class="buttons-span">
					<i class="icon google"></i>
					<button mat-button (click)="createUserWithGoogle()">Continúa con Google</button>
				</span>
        <!--
          <span class="buttons-span">
					<i class="icon facebook"></i>
					<button mat-button (click)="createUserWithFacebook()">Continúa con Facebook</button>
				</span>
        -->

			</div>
			<div class="terminos">
				<b>Al registrarte estás aceptando nuestros Términos de Uso y Política de Privacidad</b>
			</div>
		</div>
	</div>
</div>
