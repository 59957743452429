import {Injectable} from '@angular/core';
import {HelperService} from 'helpers/helper.service';
import {Property} from 'interfaces/helper.interface';
import {Negocio} from 'interfaces/negocio.interface';
import {getFunctions, HttpsCallable, httpsCallable} from 'firebase/functions';
import {orderBy, where} from 'firebase/firestore';
import {Categorias} from 'interfaces/categorias.interface';
import {Product} from 'interfaces/product.interface';
import {BehaviorSubject} from 'rxjs';
import {UserService} from './user.service';
import {limit} from '@angular/fire/firestore';
import {connectFunctionsEmulator, Functions} from '@angular/fire/functions';
import {Telefono} from 'interfaces/telefono.interface';
import {Bandera} from 'interfaces/bandera.interface';
import {FirebaseBackEndError} from 'interfaces/error.interface';

@Injectable({
	providedIn: 'root'
})
export class TiendaService {
	currentNegocio!: Negocio | null;
	private currentNegocio$ = new BehaviorSubject<Negocio | null>(null);
	//prettier-ignore
	constructor(
    private helpers: HelperService,
    private userServ: UserService,
	private functions: Functions,
  ) {
//	connectFunctionsEmulator(this.functions, 'localhost', 5001);

  }

	public createNegocio(data: Partial<Negocio>): Promise<string> {
		return httpsCallable(
			this.functions,
			'negocios-createNegocio-default'
		)({negocio: data})
			.then((res) => {
				console.log(res);
				return (res.data as {id: string}).id;
			})
			.catch((err) => {
				throw err;
			});
	}

	getNegocio$() {
		return this.currentNegocio$.asObservable();
	}

	initNegocio(uid: string) {
		return this.helpers.obtenerNegocio$(uid).subscribe((e) => {
			this.currentNegocio = e;
			this.currentNegocio$.next(e);
		});
	}

	/**
	 * Verifica si existe el correo electronico.
	 * @param {any} Service
	 * @returns
	 */
	public checkIfExistsInNegocios = async (campo: string, valor: any): Promise<any> =>
		await this.helpers
			.getInfo('negocios', campo)
			.then((negocios: any[]) =>
				(<Property<Function>>{
					name: (name: string[]) => (name[0] ? {negocioexist: true} : null),
					identificador: (identificador: string[]) => (identificador[0] ? {idexist: true} : null)
				})[campo](negocios.filter((negocio: any) => JSON.stringify(negocio) === JSON.stringify(valor)))
			)
			.catch((error: any) => this.helpers.firebaseErrors({error: error}));

	/**
	 * Verifica si el identificador existe.
	 * @param valor
	 * @returns
	 */
	public checkIndentificador = async (user: {uid: string; identificador: string}) =>
		await this.helpers
			.getInfo('negocios', 'identUID', where('identificador', '==', user.identificador))
			.then((negocios: Negocio[]) => (negocios.length > 0 && user ? (negocios[0].uid === user.uid ? null : {idexist: true}) : null));

	/**
	 * Envia un codigo de verificacion al telefono especificado.
	 * @param num
	 * @param callable
	 * @returns
	 */
	public sendCodeOtp = async (num: string, callable: HttpsCallable = httpsCallable(getFunctions(), 'otp-sendOtp-default')) =>
		await callable({phonenumber: num}).catch((err) => {
			JSON.stringify(err);
			throw err;
		});

	public sendCodeOtp2 = async (
		num: string,
		idNegocio: string,
		callable: HttpsCallable = httpsCallable(getFunctions(), 'otp-sendOtp-default')
	) =>
		await callable({phonenumber: num, idNegocio: idNegocio}).catch((err: any) => {
			console.info(JSON.stringify(err));
			const error = err as FirebaseBackEndError;
			throw {
				code: error.code,
				message: error.message,
				detail: error.detail
			} as FirebaseBackEndError;
		});

	/**
	 * Verifica el codigo enviado.
	 * @param num
	 * @param code
	 * @param callable
	 * @returns
	 */
	public checkCodeOtp = async (
		num: string,
		code: string,
		callable: HttpsCallable = httpsCallable(getFunctions(), 'otp-checkOtp-default')
	) =>
		await callable({phonenumber: num, code: code})
			.then((res: any) => res.data)
			.catch((err: any) => {
				console.log(err);
				return false;
			});

	/**
	 * Verifica el codigo enviado.
	 * @param num
	 * @param code
	 * @param callable
	 * @returns
	 */
	public checkOtp2 = async (
		idNegocio: string,
		num: string,
		code: string,
		pais: Bandera,
		tel: Telefono,
		callable: HttpsCallable = httpsCallable(getFunctions(), 'otp-checkOtp-default')
	) =>
		await callable({phonenumber: num, code: code, idNegocio: idNegocio, pais: pais, tel: tel})
			.then((res: any) => res.data)
			.catch((err: any) => {
				console.info(JSON.stringify(err));
				const error = err as FirebaseBackEndError;
				throw {
					code: error.code,
					message: error.message,
					detail: error.detail
				} as FirebaseBackEndError;
			});

	/**
	 * Obtiene todas las categorias.
	 * @param  {string} negocioId
	 * @returns Promise
	 */
	public obtenerCategorias = async (negocioId: string): Promise<Categorias[]> =>
		await this.helpers.obtenerDocumentos(
			'categorias',
			[],
			where('negocio_id', '==', negocioId),
			where('enable', '==', true),
			orderBy('name', 'asc')
		);

	/**
	 * Obtiene todos los productos.
	 * @param {string} negocioId
	 * @returns Promise
	 */
	public obtenerProductos = async (catId: string): Promise<Product[]> =>
		await this.helpers.obtenerDocumentos('productos', [], where('cat_id', '==', catId), orderBy('name', 'asc'));
	public catHasProduct = async (catId: string): Promise<boolean> =>
		await this.helpers.obtenerDocumentos('productos', [], where('cat_id', '==', catId), limit(1)).then((res) => res.length > 0);

	public async tieneNegocio() {
		const uid = await this.userServ.getUserUid();
		if (!uid) {
			return false;
		}
		// eslint-disable-next-line max-len
		return this.helpers.obtenerDocumentos('negocios', [], where('uid', '==', uid)).then((snap) => snap.length !== 0);
	}

	public getNegocioQr(negocioId: string) {
		return httpsCallable(
			this.functions,
			'negocios-getQrNegocio-getQr'
		)({negocioId: negocioId}).then((res) => {
			console.log(res);
			const data = res.data as {data_uri: string};
			return data.data_uri;
		});
	}

	public getSuggestionUserName(bussinessName: string): Promise<string> {
		return httpsCallable(
			this.functions,
			'negocios-suggestionUserName-default'
		)({businessName: bussinessName})
			.then((res) => {
				return res.data as string;
			})
			.catch((err) => {
				throw err;
			});
	}
}

