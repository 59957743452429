import {Pipe, PipeTransform} from '@angular/core';
import countryList from 'country-list-with-dial-code-and-flag';
import countryCurrency, {Countries} from 'country-to-currency';
import countryLocaleMap from 'country-locale-map';
@Pipe({
	name: 'customCurrency',
	standalone: true
})
export class CustomCurrencyPipe implements PipeTransform {
	transform(value: number, countryCode?: any, justSymbol?: boolean): any {
		return this.precioLocal(value, countryCode, justSymbol);
	}

	private precioLocal = (precio: number | undefined, countryCode?: string, justSymbol?: boolean) => {
		const returnPrecio = (curr: string, locale: string) => {
			const precioDef = precio!.toLocaleString(`${locale}`, {
				style: 'currency',
				currency: `${curr}`,
				currencyDisplay: 'symbol',
				maximumFractionDigits: 0
			});
			if (justSymbol) {
				const aux = precioDef.split(`${precio}`)[0];
				return aux;
			}
			return precioDef;
		};

		if (!countryCode) {
			return returnPrecio('CLP', 'es-CL');
		}
		const isPhoneCode = countryCode.charAt(0) === '+';
		countryCode = isPhoneCode ? countryList.findOneByDialCode(countryCode)?.code : countryCode;
		const country = countryLocaleMap.getCountryByAlpha2(countryCode as string);
		if (!country) {
			return returnPrecio('CLP', 'es-CL');
		}
		const currency = countryCurrency[countryCode as Countries];
		const locale = country?.default_locale.replace('_', '-');
		return returnPrecio(currency, locale);
	};
}

