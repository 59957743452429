/**
 * ANGULAR CORE
 */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';

/**
 * PAGINA PRINCIPAL
 */
import {EntrarCuenta} from './entrar-cuenta.page';
import {DynamicDialogModule} from 'primeng/dynamicdialog';

//prettier-ignore
@NgModule({
  declarations: [EntrarCuenta],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    DynamicDialogModule
  ],
  exports: [EntrarCuenta],
  providers: []
})
export class EntrarCuentaModule {}

