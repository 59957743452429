import {HttpClient} from '@angular/common/http';
import {HelperService} from 'helpers/helper.service';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {firstValueFrom} from 'rxjs';
import {NgxImageCompressService} from 'ngx-image-compress';
import {ImagenesCompress} from 'interfaces/imagenes.interface';
import {NgxSpinnerService} from 'ngx-spinner';
import {inject} from '@angular/core';

export class ImagesController {
	imagen!: File | null;
	imagen64!: string | null;
	imagen64Recortada!: string;

	maxWidth: number = 0; //500;
	maxHeight: number = 0; //500;

	private readonly helpers = inject(HelperService);
	private readonly http = inject(HttpClient);
	private readonly compressImg = inject(NgxImageCompressService);

	//prettier-ignore
	constructor() {}

	/**
	 * Acciona el campo de subida de imagenes.
	 * @param {string} id Identificador HTML del campo de imagenes
	 * @returns
	 */
	public clickUpload = (id: string): HTMLElement => document.getElementById(id)?.click()!;

	/**
	 * Elimina la imagen cargada.
	 */
	public clearUpload = () => {
		this.imagen = null;
		this.imagen64 = null;
	};

	/**
	 * Sube las imagenes al perfil al hacer clic.
	 * @param {EventEmitter} $event
	 * @returns {void}
	 */
	public uploadProfileImage = async () => await this.compressImg.uploadFile().then(async (file: ImagenesCompress) => await this.compressImg.compressFile(file.image, file.orientation, 100, 100, this.maxWidth, this.maxHeight));

	/**
	 * Sube las imagenes de los productos.
	 */
	public uploadImages = async (spinner?: NgxSpinnerService) => {
		const files: ImagenesCompress[] = await this.compressImg.uploadMultipleFiles();

		spinner?.show('imagenes');

		for (const file of files) {
			file.compressed = await this.compressImg.compressFile(file.image, file.orientation, 100, 100, this.maxWidth, this.maxHeight);
			file.size = this.compressImg!.byteCount(file.compressed);
		}

		spinner?.hide('imagenes');

		return files;
	};

	/**
	 * Guarda la imagen recortada en formato png.
	 * @param {string} dataUrl
	 * @param {string} fileName
	 * @returns {File}
	 */
	public fileObject = (dataUrl: string, fileName: string): File => {
		const arr: string[] = dataUrl.split(',');
		const bstr: string = atob(arr[1]);
		let n: number = bstr.length;
		const u8arr: Uint8Array = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], fileName, {type: `image/${this.helpers.extension(fileName)}`});
	};

	/**
	 * Codifica las imagenes cargadas en Base64.
	 * @param {File[]} files
	 */
	public base64 = async (files: File[] | FileList): Promise<string[]> => {
		const base64: string[] = [];

		for (let i = 0; i < files.length; i++) {
			base64[i] = await new Promise((resolve, reject): any => {
				const reader = new FileReader();
				reader.readAsDataURL(files[i]);
				reader.onload = () => resolve(reader.result?.toString()!); ///RESOLVERRRRRR PENDIENTEEEEEEEEEE
				reader.onerror = (error) => this.helpers.firebaseErrors({error: error});
			});
		}
		return base64;
	};

	/**
	 * Guarda la imagen recortada.
	 * @param event
	 * @returns
	 */
	public recorte = (event: ImageCroppedEvent) => (this.imagen64Recortada = event.base64!);

	/**
	 * Obtiene las imagenes desde Storage en Base64 mediante el middleware
	 * @param {string[]} imagenes
	 * @returns
	 */
	public getImgStorage = async (imagenes: string[]): Promise<any> => await firstValueFrom(this.http.post(`${this.helpers.middleware('prod')}/imagen/`, imagenes)).catch((error: any) => this.helpers.firebaseErrors({error: error}));
}
