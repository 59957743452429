<div class="right-wrapper">
	<div class="logo-fenix">
		<img src="assets/images/Logo_Fenix.png" alt="Logo Fenix" />
	</div>
	<div class="iniciar-sesion">
		<div class="iniciar-sesion-wrapper">
			<div style="text-align: center;" class="title">
				<h4>INICIAR SESIÓN  
				<ng-container *ngIf="ruta && ruta == '/tienda/crear'">
				Para crear tu tienda
				</ng-container>	
				</h4>
			</div>
			<div class="buttons">
				<span class="buttons-span">
					<i class="icon google"></i>
					<button id="btn-google" (click)="loginWithGoogle()">Continúa con Google</button>
				</span>
				<!--
          <span class="buttons-span">
					<i class="icon facebook"></i>
					<button (click)="logInWithFacebook()">Continúa con Facebook</button>
				</span>
        -->

			</div>
		</div>
	</div>
</div>